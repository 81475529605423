import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Page, Prev, Next } from './styles'

const Pagination = ({ activePage, setActivePage, numPages }) => (
  <Wrapper>
    <Prev
      onClick={() => setActivePage(activePage - 1)}
      disabled={activePage === 1}
    />
    {Array(numPages)
      .fill(null)
      .map((_, index) => (
        <Page
          key={index}
          onClick={() => setActivePage(index + 1)}
          active={activePage === index + 1}
        >
          {index + 1}
        </Page>
      ))}
    <Next
      onClick={() => setActivePage(activePage + 1)}
      disabled={activePage === numPages}
    />
  </Wrapper>
)

export default Pagination

Pagination.propTypes = {
  activePage: PropTypes.number,
  numPages: PropTypes.number,
  setActivePage: PropTypes.func
}
