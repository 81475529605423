import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Results, Item } from './styles'
import { fetchLocationDetails } from '~/components/Recycle/utils'

const Location = ({ location }) => {
  const [details, setDetails] = useState()

  useEffect(() => {
    async function fetchData() {
      const details = await fetchLocationDetails(location)
      setDetails(details)
    }
    fetchData()
  }, [location])

  if (!details) return null

  return (
    <Item.Wrapper>
      <Item.Name>{details.name}</Item.Name>
      <Item.Distance>{details.distance} miles</Item.Distance>
      <Item.Phone>{details.phone}</Item.Phone>
      <Item.Address>{details.address}</Item.Address>
      <Item.Hours>{details.hours}</Item.Hours>
    </Item.Wrapper>
  )
}

const RecycleResults = ({ loading, locations }) => {
  if (loading || locations.length === 0) return null

  return (
    <Results>
      {locations.map(location => (
        <Location key={location.location_id} location={location} />
      ))}
    </Results>
  )
}

export default RecycleResults

Location.propTypes = {
  location: PropTypes.shape({})
}

RecycleResults.propTypes = {
  loading: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  activePage: PropTypes.number,
  setActivePage: PropTypes.func
}
