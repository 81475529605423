import styled from '@emotion/styled'
import { Button } from 'theme-ui'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    margin: 8px 0;
  }
`

/**
 * Form
 */
export const SearchForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  margin-top: 32px;

  button {
    height: 40px;
  }

  @media screen and (min-width: 992px) {
    height: auto;
    flex-direction: row;
  }
`

export const SearchInput = styled.input`
  border: none;
  border-radius: 0;
  border-bottom: 2px solid black;
  height: 40px;
  background: none;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.primary};
  width: 200px;

  @media screen and (min-width: 992px) {
    margin-right: 16px;
  }
`

export const SearchButton = styled(Button)`
  height: 40px;
  font-size: 14px;
`
