import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Page, Metadata } from '~/components/Layout'
import RecyclePage from '~/components/Recycle/Page'

const Recycle = ({ data: { page } }) => {

  const { metadata } = page
  const metaTitle = metadata?.title
  const metaDescription = metadata?.description?.description
  const metaImage = metadata?.image?.file?.url

  return (
    <Page>
      <Metadata
        title={metaTitle || page.name}
        description={metaDescription}
        image={metaImage}
      />
    <RecyclePage />
  </Page>
  )
}

export default Recycle

Recycle.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      name: PropTypes.string
    })
  })
}

export const query = graphql`
  query RecyclePage($locale: String) {
    page: contentfulPage(
      slug: { eq: "recycle" }
      node_locale: { eq: $locale }
    ) {
      ...PageFragment
    }
  }
`
