import React, { useEffect, useState } from 'react'
import { Container } from 'theme-ui'
import Header from '~/components/Recycle/Header'
import Results from '~/components/Recycle/Results'
import { fetchLocations, fetchPostalInfo } from '~/components/Recycle/utils'
import { Wrapper, Footer } from './styles'
import Pagination from '~/components/Generic/Pagination'
import usePagination from '~/hooks/components/use-pagination'

const Recycle = () => {
  const [locations, setLocations] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const { activePage, setActivePage, numPages, pageItems } = usePagination(
    locations,
    6
  )

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const zipCode = event.target.elements.zipCode.value
    if (zipCode.length < 5) {
      setError('Invalid zip code')
      setLoading(false)
      return
    }
    const postalInfo = await fetchPostalInfo(zipCode)
    const locations = await fetchLocations(postalInfo)
    setLocations(locations)
    setLoading(false)
    setActivePage(1)
    setError(undefined)
  }

  return (
    <Wrapper>
      <Container>
        <Header loading={loading} error={error} onSubmit={handleSubmit} />
        <Results loading={loading} locations={pageItems} />
        {numPages > 1 && (
          <Pagination
            activePage={activePage}
            setActivePage={setActivePage}
            numPages={numPages}
          />
        )}
        <Footer>
          <p> Powered by </p>
          <a
            href="https://earth911.com/"
            title="Visit Earth911 - More Ideas, Less Waste"
          >
            <img alt="earth911 logo" src="//cdn.shopify.com/s/files/1/0258/6273/3906/files/earth911.png?v=6661972043411757243" />
          </a>
        </Footer>
      </Container>
    </Wrapper>
  )
}

export default Recycle
