import axios from 'axios'

export async function fetchPostalInfo(zipCode) {
  try {
    const options = {
      method: 'GET',
      url: 'https://api.earth911.com/earth911.getPostalData',
      params: {
        api_key: '3cde0c964d121659',
        country: 'US',
        postal_code: zipCode
      },
      headers: { 'Content-Type': 'application/json' }
    }
    const response = await axios.request(options)
    const { result } = await response.data
    return {
      longitude: result.longitude,
      latitude: result.latitude
    }
  } catch (error) {
    if (error) {
      console.log('Invalid zip code')
    }
  }
}

export async function fetchLocations(postalInfo) {
  try {
    const options = {
      method: 'GET',
      url: 'https://api.earth911.com/earth911.searchLocations',
      params: {
        api_key: '3cde0c964d121659',
        material_id: 388,
        max_distance: 50,
        max_results: 30,
        longitude: postalInfo.longitude,
        latitude: postalInfo.latitude
      },
      headers: { 'Content-Type': 'application/json' }
    }
    const response = await axios.request(options)
    const { result } = await response.data
    return result
  } catch (error) {
    if (error) {
      this.error = 'No locations found nearby'
    }
  }
}

export async function fetchLocationDetails(location) {
  try {
    const options = {
      method: 'GET',
      url: 'https://api.earth911.com/earth911.getLocationDetails',
      params: {
        api_key: '3cde0c964d121659',
        location_id: location.location_id
      },
      headers: { 'Content-Type': 'application/json' }
    }
    const response = await axios.request(options)
    const { result } = await response.data
    return {
      name: location.description,
      phone: result[location.location_id].phone,
      address: result[location.location_id].address,
      hours: result[location.location_id].hours,
      distance: location.distance
    }
  } catch (error) {
    if (error) {
      console.log(error)
      this.error = true
    }
  }
}
