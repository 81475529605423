import styled from '@emotion/styled'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 118px);
  padding: 64px 0;
  background: ${props => props.theme.colors.cream};

  button {
    font-weight: normal;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  p {
    font-size: 14px;
  }

  img {
    height: 16px;
    margin-left: 8px;
  }
`
