import styled from '@emotion/styled'

/**
 * Results
 */
export const Results = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin-top: 32px;

  @media screen and (min-width: 768px) {
    gap: 64px;
    margin-top: 64px;
  }

  @media screen and (min-width: 1200px) {
    gap: 64px 96px;
  }
`

export const LocationWrapper = styled.div``

export const LocationName = styled.h3`
  font-family: ${props => props.theme.fonts.secondary};
  line-height: 24px;
`
export const LocationDistance = styled.p`
  font-size: 16px;
  border-bottom: 1px solid black;
  padding-bottom: 4px;
`
export const LocationPhone = styled.p`
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid black;
  margin-top: 4px;
  padding-top: 4px;
`
export const LocationAddress = styled.p`
  font-size: 16px;
`
export const LocationHours = styled.p`
  font-size: 15px;
`

export const Item = {
  Wrapper: LocationWrapper,
  Name: LocationName,
  Distance: LocationDistance,
  Phone: LocationPhone,
  Address: LocationAddress,
  Hours: LocationHours
}
