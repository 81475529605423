import { useState } from 'react'

export default (items, itemsPerPage) => {
  const [activePage, setActivePage] = useState(1)

  const numPages =
    items.length % itemsPerPage === 0
      ? items.length / itemsPerPage
      : parseInt(items.length / itemsPerPage) + 1
  const startIndex = (activePage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage - 1
  const pageItems = items.filter(
    (_, index) => index >= startIndex && index <= endIndex
  )

  return { activePage, setActivePage, numPages, pageItems }
}
