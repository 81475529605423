import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from 'theme-ui'
import { Header, SearchForm, SearchButton, SearchInput } from './styles'
import recycleIcon from '~/assets/recycle.png'

const RecycleHeader = ({ loading, error, onSubmit }) => (
  <Header>
    <img src={recycleIcon} alt="Recycle Icon" width="100" height="100" />
    <Heading as="h1" variant="secondaryLarge">
      Ready to recycle?
    </Heading>
    <SearchForm onSubmit={onSubmit}>
      <SearchInput id="zipCode" type="text" placeholder="Zip Code" />
      <SearchButton type="submit" variant="secondary">
        Search →
      </SearchButton>
    </SearchForm>
    {error && !loading && <p style={{ color: 'red' }}>{error}</p>}
    {loading && <p>Loading...</p>}
  </Header>
)

export default RecycleHeader

RecycleHeader.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func
}
